import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Authentication/Login";
import SignUp from "./Authentication/SignUp";
import ForgotPassword from "./Authentication/ForgotPassword";
import MainLayout from "./Layout/LayoutController/MainLayout";
import { routes } from "./Routes";
import PrivateRoute from "./PrivateRoute";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { darkTheme } from "./theme";
import colorConfigs from "./configs/colorConfigs";

function App() {
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme={true} />
      <ThemeProvider theme={darkTheme}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/recoverpassword" element={<ForgotPassword />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }>
            {routes}
          </Route>
        </Routes>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
