import { createChatBotMessage } from 'react-chatbot-kit';
import Channel from './Channel';

import Data from './Data';
import Avatar from './Avatar';
import Alert from './Alert';
import DateTimePicker from './Date';


const config = {
  botName: "CondoBot",
  initialMessages: [createChatBotMessage(`Welcome to Condo 360 !`,),
    createChatBotMessage(`Please write a Message to Continue`, {
      // widget: "option"
    })],
  customComponents: {
    botAvatar: (props) => <Avatar {...props} />,
  },
  state: {
    checker: null,
    channelData: {
      channel: "",
    },
    selectedAlerts: [],
    selectedDates: {
      startDate: "",
      endDate: "",
    },
  },
  setChannel: async (selectedChannel) => {
    config.state.channelData.channel = selectedChannel;
   
  },
  setAlertData: (data) => {
    config.state.alertData = data;
  },
  setSelectedDates: (startDate, endDate) => {
    config.state.selectedDates = { startDate, endDate };
  },
  setSelectedAlerts: (selectedAlerts) => {
    config.state.selectedAlerts = selectedAlerts;
  },
 
  widgets: [
    {
      widgetName: "option",
      widgetFunc: (props) => <Channel {...props} />,
    },
    {
      widgetName: "alert",
      widgetFunc: (props) => <Alert {...props} />,
     
    },
    {
      widgetName: "date",
      widgetFunc: (props) => <DateTimePicker {...props} />,
    },
    {
      widgetName: "data",
      widgetFunc: (props) => <Data {...props} />,
    },
  ]
};

export default config;