import { Grid} from "@mui/material";
import Typography from '@mui/material/Typography';

export const Header = (props) => {
    const { title } = props;
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4">{title}</Typography>
            </Grid>
        </Grid>
    );
}


