import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  TextField,
  Typography,
  Card,
  Grid,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";

import MUIDataTable from "mui-datatables";
import DataSerices from "../Services/DataSerices";
import EnvironmentalSetUp from "./EnvironmentalSetup";
import BackDropLoading from "../Components/Loading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const accordionStyle = {
  border: "2px solid #ccc",
  width: "100%",
  borderRadius: "10px",
  transition: "border 0.3s",
  "&:hover": {
    border: "2px solid #007bff",
  },
};

const buttonContainerStyle = {
  marginTop: "10px",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const buttonStyle = {
  width: "100px",
};

const CameraSetup = () => {
  const [cameraCount, setCameraCount] = useState("");
  const [email, setEmail] = useState("");
  const [ftpCredentials, setFtpCredentials] = useState([]);
  const [secondAccordionEnabled, setSecondAccordionEnabled] = useState(false);
  const [thirdAccordionEnabled, setThirdAccordionEnabled] = useState(false);
  const [serviceName, setServiceName] = useState();
  const [currentStage, setCurrentStage] = useState();
  const [loaderSetup, setLoaderSetup] = useState(false);

  const handleNext = () => {
    registerNvrDetails();
    setSecondAccordionEnabled(true);
  };

  const hadleSubmit = () => {
    updateNvrDetails();
    setSecondAccordionEnabled(false);
    setThirdAccordionEnabled(true);
  };
  const navigate = useNavigate("");

  const handleBack = () => {
    setSecondAccordionEnabled(false);
  };

  const handleCameraCountChange = (event) => {
    setCameraCount(event.target.value);
  };

  const columns = [
    {
      name: "ftpUserName",
      label: "UserName",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ftpPassword",
      label: "Password",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "host",
      label: "Host",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "filePath",
      label: "FilePath",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const registerNvrDetails = async () => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    var userorg = "";
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      userorg = dummy[0].value;
    }
    let data = {
      emailId: email,
      cameras: cameraCount,
      stage: 1,
      organization: userorg,
    };
    await DataSerices.registerNvr(data)
      .then((response) => {
        let stage = response.data.response.body.stage;
        if (stage && stage === 1) {
          setSecondAccordionEnabled(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateNvrDetails = async () => {
    let data = {
      emailId: email,
      stage: 2,
    };
    await DataSerices.registerNvr(data)
      .then((response) => {
        let stage = response.data.response.body.stage;
        if (stage && stage === 2) {
          setThirdAccordionEnabled(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      userEmail = parsedUser.emailId;
      setEmail(userEmail);
      getNvrDetails(userEmail);
    }
  }, []);
  const getNvrDetails = async (email) => {
    try {
      const response = await DataSerices.getNvrDetails(email);
      console.log("check ===>", response);
      let stage = response.data.response.body.stage;
      let serviceName = response.data.response.body.organizationName;
      setServiceName(serviceName);
      setCurrentStage(stage);
      if (stage && stage === 1) {
        setSecondAccordionEnabled(true);
      }
      if (stage && stage === 2) {
        setThirdAccordionEnabled(true);
      }

      if (response.data.response.body.config) {
        var tableVal = [];
        var dummy = {
          ftpPassword: "",
          filePath: "",
          host: "",
          ftpUserName: "",
        };
        response.data.response.body.config.map((item) => {
          if (item.name == "ftpPassword") {
            dummy.ftpPassword = item.value;
          } else if (item.name == "filePath") {
            dummy.filePath = item.value;
          } else if (item.name == "host") {
            dummy.host = item.value;
          } else if (item.name == "ftpUserName") {
            dummy.ftpUserName = item.value;
          }
        });
        tableVal.push(dummy);
        console.log("gkpkk", tableVal);
        setFtpCredentials(tableVal);
      } else {
        console.log("exit");
      }

      setCameraCount(response.data.response.body.cameras);
    } catch (e) {
      console.error(e);
    }
  };

  const handleLoader = useCallback(
    (e) => {
      console.log("cbjbjnk", e);
      setLoaderSetup(e);
    },
    [loaderSetup]
  );

  return (
    <Grid container spacing={2} direction="row">
      {loaderSetup && <BackDropLoading open={loaderSetup} />}
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card style={accordionStyle}>
          <CardContent>
            <Accordion
              expanded={!secondAccordionEnabled && !thirdAccordionEnabled}
              onChange={() =>
                setSecondAccordionEnabled(!secondAccordionEnabled)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "red" }} />}
              >
                <Typography variant="body4">Number of Cameras</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Number of Cameras"
                    type="number"
                    value={cameraCount}
                    onChange={handleCameraCountChange}
                    style={{ marginBottom: "10px", width: "100%" }}
                  />
                </div>
                <div style={buttonContainerStyle}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    onClick={handleNext}
                    disabled={!cameraCount}
                  >
                    Next
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card
          style={{
            ...accordionStyle,
            opacity: secondAccordionEnabled ? "1" : "1",
          }}
        >
          <CardContent>
            <Accordion expanded={handleNext}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    onClick={handleBack}
                    style={{ color: "red" }}
                  />
                }
              >
                <Typography variant="body4">FTP Credentials</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  {secondAccordionEnabled && (
                    <Grid item style={{ marginLeft: "30px" }}>
                      <Typography
                        variant="body3"
                        gutterBottom
                        style={{ fontSize: "1.2rem" }}
                      >
                        FTP Credentials:
                      </Typography>
                      {ftpCredentials.length > 0 && (
                        <MUIDataTable
                          title={false}
                          data={ftpCredentials}
                          columns={columns}
                          options={{
                            selectableRows: "none",
                            print: false,
                            download: false,
                            viewColumns: false,
                            pagination: false,
                            search: false,
                            filter: false,
                          }}
                        />
                      )}{" "}
                      {ftpCredentials.length == 0 && (
                        <Typography variant="body3">
                          {" "}
                          Your Credentials is being generated
                        </Typography>
                      )}
                      <div style={buttonContainerStyle}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={buttonStyle}
                          onClick={handleBack}
                          disabled={currentStage === 2}
                        >
                          Back
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          style={buttonStyle}
                          onClick={hadleSubmit}
                          disabled={
                            currentStage === 2 || ftpCredentials.length == 0
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card style={accordionStyle}>
          <CardContent>
            <Accordion
              expanded={thirdAccordionEnabled}
              onChange={() =>
                setSecondAccordionEnabled(!secondAccordionEnabled)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "red" }} />}
              >
                <Typography variant="body4">EnvironmentalSetUp</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EnvironmentalSetUp
                  loader={(e) => handleLoader(e)}
                  ifchild={true}
                  messageBack={(e) => {
                    if (e) {
                      toast.success("Data updated sucessfully");
                    } else {
                      toast.error("Something went wrong");
                    }
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default CameraSetup;
