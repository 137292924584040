import React, { useEffect, memo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";

const styles = {
  container: {
    height: `55vh`, //getInfo == null ? `80vh`: '50vh',
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
};

function AlertTableComponent({ list }) {
  const dispatch = useDispatch();
  const { selectedIndex } = useSelector((state) => state.CustomFunctionsModal);

  console.log("Entry..", list);
  const handleClick = (e, index) => {
    console.log("handleClick", e);
    dispatch.CustomFunctionsModal.setSelectedIndex(index);
    dispatch.CustomFunctionsModal.handleImageData(e.imageUrl);
  };

  useEffect(() => {
    console.log("listing");
    if (list?.length > 0) {
      console.log("listing", list);
      dispatch.CustomFunctionsModal.handleImageData(list[0].imageUrl);
    }
  }, [list]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={styles.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Alert Type</TableCell>
              <TableCell>Channel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length > 0 &&
              list.map((values, index) => {
                return (
                  <TableRow
                    key={index}
                    hover
                    role="checkbox"
                    style={{
                      background: selectedIndex === index ? "red" : null,
                    }}
                    onClick={() => handleClick(values, index)}>
                    <TableCell>{values.displayCreateDateTime}</TableCell>
                    <TableCell>{values.alertType}</TableCell>
                    <TableCell>{values.channel}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default memo(AlertTableComponent);
