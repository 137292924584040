
import React, { useState } from "react";
import config from "./config";

const DateTimePicker = ({ actions }) => {
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setSelectedStartDate(newStartDate);

    const nextDay = new Date(new Date(newStartDate).getTime() + 24 * 60 * 60 * 1000);
    setSelectedEndDate(nextDay.toISOString().slice(0, 10));
  };

  const handleRenderClick = () => {
    if (selectedStartDate && selectedEndDate) {
      config.setSelectedDates(selectedStartDate, selectedEndDate); // Update config state with selected dates
      actions.cameraOption();
    } else {
      console.log("Please select both dates.");
    }
  };

  return (
    <div style={containerStyle}>
      <h3 style={titleStyle}>Pick a date:</h3>
      
      <input
        type="date"
        value={selectedStartDate}
        onChange={handleStartDateChange}
        style={inputStyle}
      />
      <input
        type="date"
        value={selectedEndDate}
        disabled
        style={inputStyle}
      />
      
      <button onClick={handleRenderClick} style={buttonStyle}>
        Ok
      </button>
    </div>
  );
};

const containerStyle = {
  textAlign: "center",
  padding: "20px",
  backgroundColor: "#f4f4f4",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const titleStyle = {
  fontSize: "20px",
  margin: "10px 0",
};

const inputStyle = {
  padding: "5px",
  fontSize: "16px",
  width: "100%"
};

const buttonStyle = {
  padding: "12px 24px 10px",
  fontSize: "16px",
  backgroundColor: "#3498db",
  color: "#fff",
  borderRadius: "5px",
  cursor: "pointer",
};

export default DateTimePicker;