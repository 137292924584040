import alertJSON from "../../Constants/Alert.json";
import DataSerices from "../../Services/DataSerices";
export default {
  state: {
    alertData: {
      alerts: [],
    },
    imageURL: "",
    selectedIndex: 0,
    fetchError: undefined,
    wesocketData: [],
    modolOpen: false,
    issueTotalCount: "",
    resolvedTotalCount: "",
  },
  reducers: {
    setAlertData(state, payload) {
      return {
        ...state,
        alertData: {
          alerts: [...payload],
        },
      };
    },
    setTotalCounts(state, payload) {
      return {
        ...state,
        issueTotalCount: payload.issueTotalCount,
        resolvedTotalCount: payload.resolvedTotalCount,
      };
    },
    setModalOpen(state, payload) {
      return {
        ...state,
        modolOpen: payload,
      };
    },
    setAlertImage(state, payload) {
      return {
        ...state,
        imageURL: payload,
      };
    },
    setSelectedIndex(state, payload) {
      return {
        ...state,
        selectedIndex: payload,
      };
    },
    setWebSocketData(state, payload) {
      return {
        ...state,
        wesocketData: [...payload],
      };
    },
    setError(state, payload) {
      return {
        ...state,
        fetchError: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async handleAlertData(payload) {
      try {
        dispatch.CustomFunctionsModal.setPatrollData(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async handleImageData(payload) {
      try {
        dispatch.CustomFunctionsModal.setAlertImage(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async handleInitialRendering(payload) {
      try {
        var alertDetails = await DataSerices.getAlertsDetails({
          page: 0,
          size: 200,
          orgName: payload,
        });
        if (
          alertDetails &&
          alertDetails.data &&
          alertDetails.data.response &&
          alertDetails.data.response.body &&
          alertDetails.data.response.body.alerts
        ) {
          if (alertDetails.data.response.body.alerts.length > 0) {
            let dummyArray = [...alertDetails.data.response.body.alerts];
            dispatch.CustomFunctionsModal.setAlertData([...dummyArray]);
          } else {
            dispatch.CustomFunctionsModal.setAlertData([]);
          }
          let counts = {
            issueTotalCount:
              alertDetails?.data?.response?.body?.issueTotalCount,
            resolvedTotalCount:
              alertDetails?.data?.response?.body?.resolvedTotalCount,
          };
          dispatch.CustomFunctionsModal.setTotalCounts(counts);

          dispatch.CustomFunctionsModal.setAlertImage();
          dispatch.CustomFunctionsModal.setSelectedIndex(0);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleAlertData(payload) {
      try {
        let data = [];
        dispatch.CustomFunctionsModal.setPatrollData(data);
      } catch (error) {
        console.log(error);
      }
    },
  }),
};
