import React, { useEffect, useState } from "react";
import DataSerices from "../../Services/DataSerices";
import config from "./config";

const Channel = (props) => {
  const [channelsData, setChannelsData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      userEmail = parsedUser.emailId;
      getChannelDetails(userEmail);
      console.log("o",userEmail);
    }
  }, []);

  const getChannelDetails = async (email) => {
    try {
      const response = await DataSerices.getChannel(email);

      if (response && response.data && response.data.response.body ) {
        console.log("oioi",response);

        const menuItems = response.data.response.body.map((item) => ({
          channel: item.channel,
        }));

        setChannelsData(menuItems);
      } else {
        setError("API response does not contain expected data.");
      }
    } catch (error) {
      console.error("Error fetching channels data:", error);
      setError("Error fetching data from the API.");
    }
  };

  const buttonsMarkup = channelsData.map((channel1) => (
    <button
      style={{
        padding: "10px 20px",
        margin: "5px",
        
        backgroundColor: "#D04848",
        color: "#fff",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      key={channel1.id}
      onClick={() => {
        console.log("Selected Channel:", channel1.channel);
        config.setChannel(channel1.channel);
        props.actions.alertOption();
      }}
    >
      {channel1.channel}
    </button>
  ));

  return <div>{buttonsMarkup}</div>;
};

export default Channel;