import httpChat from "./http-chat";
import http_common from "./http-common";
import httpCommonWegrow from "./http-commonWegrow";

/* /api/user/role/{role}/status/{status} */
/* /api/user/status */

const getUserList = (data) => {
  return http_common.get(`/user/role/${data.role}/status/${data.status} `);
};

const updateUser = (data) => {
  return http_common.put(`/user/status`, data);
};

const updateUserDetails = (data) => {
  return http_common.put(`/user`, data);
};

const registerAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/registration`, data);
};

const editAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/edit`, data);
};

const getAccessList = (data) => {
  return http_common.get(`/condo/accessCard/list?status=${data.status}`);
};

const deleteAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/delete`, data);
};

const addAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/add`, data);
};

const getAccessLogs = (data) => {
  return http_common.get(
    `/condo/accessCard/logs?pageNo=${data.page}&pageSize=${data.size}&type=${data.type}`
  );
};

const getVehicleAuditReport = (data) => {
  return http_common.get(
    `/visitorInfo/audit/data?overstay=${data.overstay}&type=${data.type}&pageNo=${data.pageNo}&pageSize=${data.pageSize}&vehicleNo=${data.filterData.vehicleNo}&visitorType=${data.filterData.visitorType}`
  );
};

const searchEntryExit = (data) => {
  let x = data.field;
  let a =
    x.category == `All`
      ? ""
      : x.category.length > 0
      ? `&category=${data.field.category}`
      : "";
  let b = x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c = x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d = x.vehicleNo.length > 0 ? `&vehicleNos=${data.field.vehicleNo}` : "";
  let e = x.contactNo.length > 0 ? `&mobileNo=${data.field.contactNo}` : "";
  let k = x.monthWise.length > 0 ? `&monthYear=${data.field.monthWise}` : "";
  let f = a + b + c + d + e + k;
  return http_common.get(
    `/vehicle/search/entryexit?pageNo=${data.pageNo}&pageSize=${data.pageSize}${f}`
  );
};

const downloadEntryExitExcel = (data) => {
  let x = data && data.field && data.field;
  let b = x && x.name && x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c =
    x && x.block && x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d =
    x && x.vehicleNo && x.vehicleNo.length > 0
      ? `&vehicleNos=${data.field.vehicleNo}`
      : "";
  let e =
    x && x.contactNo && x.contactNo.length > 0
      ? `&mobileNo=${data.field.contactNo}`
      : "";
  let k =
    x && x.monthWise && x.monthWise.length > 0
      ? `&monthYear=${data.field.monthWise}`
      : "";
  let f = b + c + d + e + k;
  let url;
  if (data) {
    url = `/vehicle/search/entryexit/csv?${f}`;
  } else {
    url = `/vehicle/search/entryexit/csv`;
  }
  return http_common.get(url);
};

const onRaiseCase = (data) => {
  return http_common.post(`/condo/accessCard/add`, data);
};

const onMarkAsResolved = (data) => {
  return http_common.post(`/condo/accessCard/add`, data);
};

const getAlertsDetails = (data) => {
  let z = `alerts?page=${data.page}&size=${data.size}`;
  let x = data?.alertType ? `&alertType=${data.alertType}` : "";
  let y = data?.channel ? `&channel=${data.channel}` : "";
  let w = data?.fromDate ? `&fromDate=${data.fromDate}` : "";
  let v = data?.toDate ? `&toDate=${data.toDate}` : "";
  let u = `&orgName=${data.orgName}`;
  let url = z + x + y + w + v + u;
  return httpCommonWegrow.get(url);
};
const handleRaiseOrResolveCase = (data) => {
  return httpCommonWegrow.put(`alerts`, data);
};

const getChannelList = (data) => {
  return httpCommonWegrow.get(`alerts/channels?orgName=${data}`, data);
};

const handlePatrolAlert = (data) => {
  return httpCommonWegrow.put(`alerts`, data);
};

const getNvrDetails = (data) => {
  return httpCommonWegrow.get(`/nvr/details?emailId=${data}`);
};

const getAllNvrDetails = (data) => {
  return httpCommonWegrow.get(`/nvr/approval/details?emailId=${data}`);
};

const registerNvr = (data) => {
  return httpCommonWegrow.post(`/nvr/create`, data);
};

const updateNvrCredentials = (data) => {
  return httpCommonWegrow.post(`/nvr/credentials`, data);
};
const environmentalSetup = (data) => {
  return httpCommonWegrow.get(`/nvr/environment/setup?emailId=${data}`);
};

const handleEnvSetup = async (data) => {
  return await httpCommonWegrow.post(`/nvr/environment/setup`, data);
};

const getChannel = (data) => {
  return httpChat.get(`nvr/environment/setup?emailId=${data}`);
};

const getAlert = (data) => {
  return httpChat.get(
    `nvr/events?emailId=${data.email}&channel=${data.channel}`
  );
};

export default {
  getUserList,
  updateUser,
  registerAccessCard,
  getAccessList,
  deleteAccessCard,
  addAccessCard,
  editAccessCard,
  getAccessLogs,
  updateUserDetails,
  getVehicleAuditReport,
  searchEntryExit,
  downloadEntryExitExcel,
  onRaiseCase,
  onMarkAsResolved,
  getAlertsDetails,
  handleRaiseOrResolveCase,
  getChannelList,
  handlePatrolAlert,
  getNvrDetails,
  registerNvr,
  getAllNvrDetails,
  updateNvrCredentials,
  environmentalSetup,
  handleEnvSetup,
  getChannel,
  getAlert,
};
