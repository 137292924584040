// ActionProvider Component

import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  
  const cameraOption = () => {
    const message = createChatBotMessage("Click the camera id which you want to see ", {
      widget: "option"
    });
    updateState(message);
  };

  const alertOption = (channel) => {
      updateState(  createChatBotMessage(`please select the alert of ${channel} `, {
          widget: "alert",
          
        }));
     
  };
  
  const dateOption = () => {
    updateState(createChatBotMessage("Got it", { widget: "date" }));
  };

  const imageOption = () => {
    updateState(createChatBotMessage("Got it", { widget: "data" }));
  };

  const continueOption = () => {
    updateState(createChatBotMessage("Do you want to continue?", {}));
  };

  const updateState = (message, checker, selectedChannel) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
     
    }));
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            cameraOption,
            alertOption,
            dateOption,
            imageOption,
            continueOption,
          },
       
        });
      })}
    </div>
  );
};

export default ActionProvider;
