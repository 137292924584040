const Zone = [
  {
    value: "zone1",
    label: "Zone 1",
  },
  {
    value: "zone2",
    label: "Zone 2",
  },
  {
    value: "zone3",
    label: "Zone 3",
  },
  {
    value: "zone4",
    label: "Zone 4",
  },
  {
    value: "zone5",
    label: "Zone 5",
  },
  {
    value: "zone6",
    label: "Zone 6",
  },
  {
    value: "zone7",
    label: "Zone 7",
  },
  {
    value: "zone8",
    label: "Zone 8",
  },
  {
    value: "zone9",
    label: "Zone 9",
  },
];

const AlertDetails = [
  {
    value: "illegalDumping",
    label: "Illegal Dumping",
  },
  {
    value: "noParking",
    label: "No Parking",
  },
];

const Condo = [
  {
    value: "condo",
    label: "condo",
  },
];

const gender = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "others",
    label: "Others",
  },
];

const channel = [
  {
    value: "ch",
    label: "ch",
  },
];

export default {
  AlertDetails,
  Zone,
  Condo,
  gender,
  channel,
};
