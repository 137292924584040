import logger from "redux-logger";
import { init } from "@rematch/core";
import models from "../appModels";

export default init({
  models,
  // plugins: [loadingPlugin, persistPlugin],
  redux: {
    middlewares: [],
  },
});
