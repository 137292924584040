import React, { useState } from 'react';
import Modal from 'react-modal';
import 'react-chatbot-kit/build/main.css';
 import './Chat.css'
import Chatbot from 'react-chatbot-kit';
import Fab from "@mui/material/Fab"; 
import SmartToyIcon from '@mui/icons-material/SmartToy'; 
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';



function Chat() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Fab onClick={openModal} style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999' }}>
        <SmartToyIcon />
      </Fab>

      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Chat Modal"
        ariaHideApp={false}
        
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
          },
          content: {
            width: '300px', 
            height: '500px', 
            margin: 'auto',
            top: '30px',
            right: '40px',
            bottom: '30px',
            left: 'auto',
            borderRadius: '15px',
            padding: '0px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0)', 
            backgroundColor: 'transparent', 
            border: 'none', 
          },
        }}
      >
      <div className='Chat'>
        <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} /> 
        </div>
      </Modal>
    </div>
  );
}

export default Chat;
