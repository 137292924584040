
import React, { useEffect, useState } from "react";
import DataSerices from "../../Services/DataSerices";
import config from "./config";

const Alert = (props) => {
  const [alertData, setAlertData] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;

    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      userEmail = parsedUser.emailId;
      getAlertDetails(userEmail, config.state.channelData.channel);
      console.log("aa", userEmail, config.state.channelData.channel);
    }
  }, [config.state.channelData.channel]);

  const getAlertDetails = async (email, channel) => {
    try {
      let data = {
        email: email,
        channel: channel,
      };
      const response = await DataSerices.getAlert(data);

      if (response && response.data && response.data.response.body) {
        const menuItems = response.data.response.body.map((item) => ({
          event: item.event,
        }));
        setAlertData(menuItems);
        config.setAlertData(menuItems); // Update config state with alert data
      } else {
        setError("API response does not contain expected data.");
      }
    } catch (error) {
      console.error("Error fetching alert data:", error);
      setError("Error fetching data from the API.");
    }
  };

  const handleButtonClick = (event) => {
    if (selectedAlerts.includes(event)) {
      setSelectedAlerts((prevSelectedAlerts) =>
        prevSelectedAlerts.filter((selectedEvent) => selectedEvent !== event)
      );
    } else {
      setSelectedAlerts((prevSelectedAlerts) => [...prevSelectedAlerts, event]);
    }
  };

  const buttonsMarkup = alertData.map((alert1) => (
    <button
      style={{
        padding: "5px 15px",
        margin: "5px",
       
        backgroundColor: selectedAlerts.includes(alert1.event) ? "#4CAF50" : "#D04848",
        color: "#fff",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      key={alert1.event}
      onClick={() => handleButtonClick(alert1.event)}
    >
      {alert1.event}
    </button>
  ));

  const handleOkClick = () => {
    console.log("Selected Alerts:", selectedAlerts);
    config.state.selectedAlerts = selectedAlerts; // Update config state with selected alerts
    props.actions.imageOption();
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!alertData) {
    return <div>Loading...</div>;
  }

  

  return (
    <div>
      {buttonsMarkup}
      <button
        style={{
          padding: "10px 20px",
          margin: "5px",
          fontSize: "16px",
          backgroundColor: "#4CAF50",
          color: "#fff",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={handleOkClick}
        disabled={selectedAlerts.length === 0} 
      >
        OK
      </button>
    </div>
  );
};

export default Alert;