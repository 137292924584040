import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import StreamRoundedIcon from "@mui/icons-material/StreamRounded";
import AddAlertRoundedIcon from "@mui/icons-material/AddAlertRounded";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ReportsInfo from "../../Constants/zone";
import LiveFeedStreaming from "./LiveFeedStreaming";
import AlertStreaming from "./Alert/AlertStreaming";
import PatrolStreaming from "./Patrol/Patrol";
import DropDownContext from "../Context/DropdowmContext";
import { useDispatch, useSelector } from "react-redux";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LiveFeedsDashboard = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [condo, setCondo] = React.useState(ReportsInfo.Condo[0].value);

  const [dropVal, setDropVal] = React.useState(ReportsInfo.Condo);

  const handleTabChange = async (e, n) => {
    setValue(n);
    if (n == 0) {
      await dispatch.CustomFunctionsModal.handleInitialRendering(condo);
    }
  };

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userRole = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      setDropVal(dummy);
    }
  }, []);
  useEffect(() => {
    setCondo(dropVal[0].value);
    if (dropVal[0].value !== "condo") {
      dispatch.CustomFunctionsModal.handleInitialRendering(dropVal[0].value);
    }
  }, [dropVal]);

  const handlecondoChange = async (e) => {
    setCondo(e);
    await dispatch.CustomFunctionsModal.handleInitialRendering(e);
  };

  return (
    <DropDownContext.Provider value={condo}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: `-20px` }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10} style={{ padding: `10px` }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="icon position tabs example"
                scrollButtons="auto"
                variant="fullWidth"
              >
                {/*  <Tab icon={<StreamRoundedIcon />} iconPosition="start" label="Live Feeds"  {...a11yProps(0)}/> */}
                <Tab
                  icon={<AddAlertRoundedIcon />}
                  iconPosition="start"
                  label="Alerts"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<SecurityOutlinedIcon />}
                  iconPosition="start"
                  label="Patrol"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={2} md={2} style={{ padding: `30px` }}>
              <TextField
                id="outlined-select-currency"
                select
                label="Condo Name"
                size="small"
                fullWidth
                value={condo}
                onChange={(e) => handlecondoChange(e.target.value)}
              >
                {dropVal.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/*  {value == 0 &&(
                  <LiveFeedStreaming />
                )} */}
          {value == 0 && <AlertStreaming dropData={condo} />}
          {value == 1 && <PatrolStreaming dropData={condo} />}
        </Grid>
      </Grid>
    </DropDownContext.Provider>
  );
};

export default LiveFeedsDashboard;

/*
 <Tabs
    value={value}
    //onChange={handleChange}
    aria-label="icon position tabs example"
  >
    <Tab icon={<PhoneIcon />} label="top" />
    <Tab icon={<PhoneMissedIcon />} iconPosition="start" label="start" />
    <Tab icon={<FavoriteIcon />} iconPosition="end" label="end" />
    <Tab icon={<PersonPinIcon />} iconPosition="bottom" label="bottom" />
  </Tabs>
*/
